import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AutofocusDirective,
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  IconButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  TooltipDirective,
  TrackByPipe,
} from '@dougs/ds';
import { OrderedSectionNames } from '@dougs/revenue/services/dto';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { CountServicesPipe } from '../../pipes/count-services.pipe';
import { SELECTABLE_TAB, ServicesModalService } from '../../services/services-modal.service';
import { ServicesListComponent } from './services-list/services-list.component';

@Component({
  selector: 'dougs-services-modal',
  templateUrl: './services-modal.component.html',
  styleUrls: ['./services-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutofocusDirective,
    ButtonComponent,
    CheckboxComponent,
    CommonModule,
    ControlFormFieldDirective,
    FormFieldComponent,
    FormsModule,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    ReactiveFormsModule,
    TooltipDirective,
    TrackByPipe,
    IconButtonComponent,
    ServicesListComponent,
    CountServicesPipe,
  ],
  providers: [ServicesModalService],
})
export class ServicesModalComponent {
  OrderedSectionNames = OrderedSectionNames;
  SELECTABLE_TAB = SELECTABLE_TAB;

  constructor(
    public readonly userStateService: UserStateService,
    protected readonly servicesModalService: ServicesModalService,
    public readonly modalRef: ModalRef,
    @Inject(MODAL_DATA)
    public data: {
      isSelectable: boolean;
      isInUserMenu: boolean;
    },
  ) {
    this.servicesModalService.setModal({
      isSelectable: data.isSelectable,
    });
  }

  protected trackByDirectValue<T = unknown>(value: T): T {
    return value;
  }

  protected readonly USER_FLAG = USER_FLAG;
}

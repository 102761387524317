import { Pipe } from '@angular/core';
import { ServiceRepository } from '@dougs/revenue/services/dto';

@Pipe({
  name: 'countServices',
  standalone: true,
})
export class CountServicesPipe {
  transform(value: ServiceRepository): number {
    if (!value) {
      return 0;
    }

    return Object.entries(value).reduce((acc, [_, services]) => acc + services.length, 0);
  }
}

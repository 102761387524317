import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent, IconButtonComponent, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { ServiceRepository, SERVICES_SECTIONS } from '@dougs/revenue/services/dto';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { ServicesModalService } from '../../../services/services-modal.service';

@Component({
  selector: 'dougs-services-list',
  standalone: true,
  imports: [CommonModule, CheckboxComponent, IconButtonComponent, TrackByPipe, TooltipDirective, FormsModule],
  templateUrl: './services-list.component.html',
  styleUrl: './services-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesListComponent {
  protected readonly USER_FLAG = USER_FLAG;
  protected readonly SERVICES_SECTIONS = SERVICES_SECTIONS;

  @Input() section!: {
    value: keyof typeof SERVICES_SECTIONS;
  };

  @Input() services!: ServiceRepository;
  @Input() isInUserMenu = false;

  constructor(
    public readonly servicesModalService: ServicesModalService,
    public readonly userStateService: UserStateService,
  ) {}
}

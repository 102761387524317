import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, concatMap, map, Observable, startWith } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { toPromise } from '@dougs/core/utils';
import { ModalRef, ModalService } from '@dougs/ds';
import { ServiceRepository, ServiceTemplate } from '@dougs/revenue/services/dto';
import { CatalogServicesStateService } from '@dougs/services/shared';

export enum SELECTABLE_TAB {
  IN_PROGRESS = 'in-progress',
  ARCHIVED = 'archived',
}

@Injectable()
export class ServicesModalService {
  public modalRef!: ModalRef;

  public isSelectable!: boolean;

  public searchControl: FormControl<string | null> = new FormControl('');
  public selectedServices: ServiceTemplate[] = [];

  private readonly selectedTab: WritableSignal<SELECTABLE_TAB.IN_PROGRESS | SELECTABLE_TAB.ARCHIVED> = signal(
    SELECTABLE_TAB.IN_PROGRESS,
  );
  selectedTab$: Signal<SELECTABLE_TAB.IN_PROGRESS | SELECTABLE_TAB.ARCHIVED> = this.selectedTab.asReadonly();

  refreshServices$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap(() => this.catalogServicesStateService.refreshServices()),
  );

  public search$: Observable<ServiceRepository> = combineLatest([
    this.searchControl.valueChanges.pipe(startWith(''), debounceTime(300)),
    this.catalogServicesStateService.services$,
  ]).pipe(
    map(([_, serviceRepository]: [string | null, ServiceRepository]) =>
      this.searchControl.value
        ? this.searchOnDescription(serviceRepository, this.searchControl.value || '')
        : serviceRepository,
    ),
  );

  services$: Observable<ServiceRepository> = this.search$.pipe(
    map((serviceRepository) => this.filterOnDeprecated(serviceRepository, false)),
  );

  servicesDeprecated$: Observable<ServiceRepository> = this.search$.pipe(
    map((serviceRepository) => this.filterOnDeprecated(serviceRepository, true)),
  );

  constructor(
    private readonly modalService: ModalService,
    private readonly catalogServicesStateService: CatalogServicesStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  public setModal({ isSelectable }: { isSelectable: boolean }): void {
    this.isSelectable = isSelectable;
  }

  public onModelChange(isSelected: boolean, value: ServiceTemplate): void {
    if (isSelected) {
      this.selectedServices = [...this.selectedServices, value];
    } else {
      this.selectedServices = this.selectedServices.filter((service) => service.id !== value.id);
    }
  }

  public async editService(service?: ServiceTemplate): Promise<void> {
    const { EditServiceModalComponent } = await import('../modals/edit-service-modal/edit-service-modal.component');
    await toPromise(this.modalService.open(EditServiceModalComponent, { data: service }).afterClosed$);
  }

  selectTab(tab: SELECTABLE_TAB): void {
    this.selectedTab.set(tab);
  }

  private searchOnDescription(serviceRepository: ServiceRepository, searchText: string): ServiceRepository {
    return Object.assign(
      {},
      ...Object.entries(serviceRepository).map(([key, value]) => ({
        [key]: value.filter((service) => service.description.toUpperCase().includes(searchText.trim().toUpperCase())),
      })),
    );
  }

  private filterOnDeprecated(serviceRepository: ServiceRepository, isDeprecated = false): ServiceRepository {
    return Object.assign(
      {},
      ...Object.entries(serviceRepository).map(([key, value]) => ({
        [key]: value.filter((service) => {
          if (isDeprecated && service.config?.deprecated === true) {
            return true;
          }

          return !isDeprecated && service.config?.deprecated !== true;
        }),
      })),
    );
  }
}

<ng-container *ngIf="servicesModalService.refreshServices$ | async"></ng-container>
<div dougsModalTitle>
  <h6>Catalogue des prestations</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <div class="services__search mb-16">
    <dougs-form-field [noMargin]="true">
      <i class="fal fa-search" dougsFormFieldPrefix></i>
      <input
        dougsAutofocus
        type="text"
        [formControl]="servicesModalService.searchControl"
        dougsFormFieldControl
        placeholder="Rechercher"
      />
    </dougs-form-field>
    <dougs-button
      *ngIf="
        (userStateService.loggedInUser$ | async).flags.includes(USER_FLAG.CAN_ACCESS_AND_UPDATE_SERVICE_TEMPLATES) &&
        data.isInUserMenu
      "
      color="dark-primary"
      (click)="servicesModalService.editService()"
    >
      <i class="fal fa-plus-large mr-8"></i> Ajouter
    </dougs-button>
  </div>
  <div
    class="services__tabs mt-16"
    *ngIf="
      (userStateService.loggedInUser$ | async).flags.includes(USER_FLAG.CAN_ACCESS_AND_UPDATE_SERVICE_TEMPLATES) &&
      data.isInUserMenu
    "
  >
    <ul>
      <li
        [ngClass]="{ active: servicesModalService.selectedTab$() === SELECTABLE_TAB.IN_PROGRESS }"
        class="py-4"
        (click)="servicesModalService.selectTab(SELECTABLE_TAB.IN_PROGRESS)"
      >
        En cours ({{ servicesModalService.services$ | async | countServices }})
      </li>
      <li
        [ngClass]="{ active: servicesModalService.selectedTab$() === SELECTABLE_TAB.ARCHIVED }"
        class="py-4"
        (click)="servicesModalService.selectTab(SELECTABLE_TAB.ARCHIVED)"
      >
        Archivées ({{ servicesModalService.servicesDeprecated$ | async | countServices }})
      </li>
    </ul>
  </div>
  <ng-container *ngIf="servicesModalService.selectedTab$() === SELECTABLE_TAB.IN_PROGRESS">
    <ng-container *ngIf="servicesModalService.services$ | async as services">
      <div class="services mt-8" *ngFor="let section of OrderedSectionNames | keyvalue; trackBy: trackByDirectValue">
        <dougs-services-list
          *ngIf="services?.[section.value]?.length > 0"
          [section]="section"
          [services]="services"
          [isInUserMenu]="data.isInUserMenu"
        />
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="servicesModalService.selectedTab$() === SELECTABLE_TAB.ARCHIVED">
    <ng-container *ngIf="servicesModalService.servicesDeprecated$ | async as servicesDeprecated">
      <div class="services mt-8" *ngFor="let section of OrderedSectionNames | keyvalue; trackBy: trackByDirectValue">
        <dougs-services-list
          *ngIf="servicesDeprecated?.[section.value]?.length > 0"
          [section]="section"
          [services]="servicesDeprecated"
          [isInUserMenu]="data.isInUserMenu"
        />
      </div>
    </ng-container>
  </ng-container>
</div>
<div dougsModalFooter *ngIf="servicesModalService.isSelectable">
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button type="button" (click)="modalRef.close(servicesModalService.selectedServices)"> Valider </dougs-button>
</div>
